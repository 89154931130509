'use strict';

import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';

import { Observable, from, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { AuthService } from '@shared/services/authService/auth.service';
import { StorageService } from '@shared/services/storageService/storage.service';
import { AlertService } from '@shared/services/alertService/alert.service';

import { configuration } from '@shared/properties/configuration';

import { environment } from '@environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  get translateService() {
    return this.injector.get(TranslateService);
  }
  constructor(
    private router: Router,
    private authService: AuthService,
    private alertService: AlertService,
    private storageService: StorageService,
    private injector: Injector
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.addAuthHeader(req, next));
  }

  private async addAuthHeader(request: HttpRequest<any>, next: HttpHandler) {
    if (request.url.startsWith(environment.resourceServerUrl)) {
      let headers;

      const accessToken = this.authService.getUserAccessToken();
      const apiGatewayAccessToken = await this.authService.getApiGatewayAccessToken();

      headers = request.headers.set('Authorization', 'MARSH-SPA userAccessToken=' + accessToken + ',apiGatewayAccessToken='
        + apiGatewayAccessToken);
        headers = headers.set('sessionid', apiGatewayAccessToken);

      /* headers = headers.set('Authorization', 'Bearer ' + accessToken); */
      if (!headers.has('App-Lang')) {
        const selectedLanguage = this.storageService.hasObject('currentLanguage', configuration.storageTypes.SessionStorage) ?
          this.storageService.getObject('currentLanguage', configuration.storageTypes.SessionStorage) : configuration.applicationLocale.default;

        headers = headers.set('App-Lang', selectedLanguage);
      }

      if (!headers.has('App-Client-Id') && this.storageService.hasObject('currentPerimeter', configuration.storageTypes.SessionStorage)) {
        const currentPerimeter = this.storageService.getObject('currentPerimeter', configuration.storageTypes.SessionStorage);

        if (currentPerimeter) {
          headers = headers.set('App-Client-Id', `${currentPerimeter.clientId}`);
        }
      }

      request = request.clone({
        headers
      });
    }

    return next.handle(request)
      .pipe(
        /* timeout(timeoutValueNumeric), */
        tap((event) => {

          if (event instanceof HttpResponse) {

            // intercept success responses

            return event;
          }
        }),
        catchError(
          (err) => {
            if (err instanceof HttpErrorResponse) {
              /* if (err.error.message) {
                this.alertService.displayErrorAlert(err.error.message);
              } */

              console.log(err);

              if (err.url.startsWith(environment.resourceServerUrl)) {
                this.authService.logError(err);
              }

              switch (err.status) {
                case 0:
                  if (err.error.message) {
                    this.alertService.displayErrorAlert(err.error.message);
                  } else {
                    this.alertService.displayErrorAlert('Error occurred while fetching response');
                  }

                  return throwError(err);

                case 401:

                  this.alertService.displayErrorAlert(`Vous n'êtes pas connecté à Marsh BCP, veuillez vous connecter et entrer vos identifiants`);

                  this.router.navigate(['client-selection']);

                  return throwError(err);

                case 403:

                  if (err.error.message) {
                    this.alertService.displayErrorAlert(err.error.message);
                  } else {
                    this.alertService.displayErrorAlert('Please contact System Administrator', 'Error Occurred', null, 5000);
                  }

                  return throwError(err);

                case 422:
                  let errCode = '';
                  let msg = err.error.message;
                  let parameter;

                  if (err.error.data) {
                    const errList = err.error.data.errors;

                    for (const errData of errList) {
                      if (errData.errors.length > 0) {
                        const errorObj = errData.errors.find(errObj => errObj.code !== '');

                        if (errorObj) {
                          errCode = errorObj.code;
                          parameter = errorObj.parameter;
                          break;
                        }
                      }
                    }
                  }

                  if (errCode) {
                    msg = this.translateService.instant(`backendErrors.${errCode}`);
                    if (errCode === 'msgPerimeterFailedErr') {
                      if (parameter) {
                        msg = msg.replace('<someParameter>', parameter);
                      }
                    }
                  }

                  this.alertService.displayErrorAlert(msg);

                  return throwError(err);

                case 500:
                case 502:
                case 504:

                  if (err.error.message) {
                    this.alertService.displayErrorAlert(err.error.message);
                  } else {
                    this.alertService.displayErrorAlert('Please contact System Administrator', 'Error Occurred', null, 5000);
                  }

                  return throwError(err);

                case 404:
                  this.alertService.displayErrorAlert(this.translateService.instant('backendErrors.msgURLNotFoundErr'));
                  return throwError(err);

                default:
                  return throwError(err);
              }
            }
          }
        )
      ).toPromise();
  }
}
